.noti-cont {
  background: var(--background);
}

.noti-cont h2 {
  color: var(--text);
}
.noti-cont .ant-divider {
  background-color: var(--text);
}

.noti-cont .noti-caed {
  padding: 20px 10px;
  border: 1px solid var(--borderLight);
  margin-bottom: 10px;
  border-radius: 20px;
  box-shadow: var(--cardShadow);
  min-height: 110px;
}

.noti-cont .notisCard span {
  color: var(--text);
  margin-top: 10px;
}

.noti-add-cont h2{
    color: var(--text);
}
.noti-add-cont .ant-divider{
    background-color: var(--text);
    margin-bottom: 80px;
}
.noti-add-cont .ant-input{
    background: var(--background);
    border: 1px solid var(--borderLight);
    color: var(--text);
    width: 80%;
    padding: 5px;
    margin-bottom: 50px;
}
.reports-main h1,
.reports-main h2,
.reports-main p {
  font-size: 18px;
  color: var(--text);
  margin-top: 0.6rem;
  text-decoration: underline;
  margin-bottom: 40px;
}
.reports-main .ant-divider-horizontal {
  border-color: var(--text);
}
.reports-main .mt-7 {
  margin-top: 5rem;
}

.mobile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background);
  padding: 10px 4px;
  padding-right: 14px;
}
.m-drawer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.m-header {
  background-color: var(--background) !important;
  color: var(--text);
}
.m-children {
  padding: 14px;
  background-color: var(--background) !important;
  min-height: 89.5vh;
}
.backArrow {
  font-size: 20px;
  color: var(--text);
}
.m-drawer .logo {
  height: 20px;
}
.headerLft {
  display: flex;
  align-items: center;
}
.notiBtn {
  background-color: var(--background);
  border: none;
  color: var(--text);
  margin-right: 10px;
}
/* .notiBtn:hover {
  background-color: var(--background) !important;
  color: var(--text) !important;
  border: none;
  margin-right: 10px;
}
.notiBtn:focus{
  background-color: var(--background) !important;
  color: var(--text) !important;
  border: none;
  margin-right: 10px;
} */

.notiIcon {
  font-size: 28px;
  color: var(--text);
}

.notiCount {
  position: absolute;
  right: 110px;
  top: 15px;
  z-index: 1000;
  background-color: rgb(253, 68, 68);
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 7px;
  border-radius: 50%;
  font-weight: 1000;
  font-size: 10px;
  color: white !important;
}

.notiComp {
  background-color: var(--background);
  /* background-color: white; */
  border: 2px solid gray;
  color: var(--text);
  position: absolute;
  right: 50px;
  top: 60px;
  max-width: 300px;
  max-height: 400px;
  min-height: 400px;
  min-width: 300px;
  padding: 10px;
  line-height: 20px !important;
  overflow: auto;
  z-index: 1000;
}
.notiHeader {
  height: 30px;
  border-bottom: 1px solid gray;
  padding: 5px;
}
.notiHeader span {
  color: var(--text);
}

.notiFooter {
  margin-top: 10px;
  text-align: center;
}

.notiCard {
  height: auto;
  max-height: 100px;
  min-height: 80px;
  border-bottom: 1px solid gray;
  padding: 5px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  /* width: 100%; */
}

 .message {
  color: var(--text);
  font-size: 12px;
  width: 70%;
}
.date{
  color: var(--text);
  font-size: 10px;
  width: 30%;
  text-align: right;
  padding-left: 5px;
}

.notiCard button {
  font-size: 10px;
  font-weight: 500;
  width: max-content;
  height: fit-content;
  margin-top: 10px;
  outline: none !important;
}

.notiCard .ant-btn:focus {
  outline: none !important;
  border: none !important;
}


@media (max-width: 800px) {
  .ant-drawer-content-wrapper {
    width: 270px !important;
  }
}

@media (max-width: 650px) {
  .sidebar-menu {
    padding-left: 0 !important;
  }
  .logout-menu {
    padding-left: 24px !important;
  }
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

.admin-project-container .box {
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  padding: 20px;
  margin: 10px;
  background-color: var(--cardColor);
}

.admin-project-container .header {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
}
.admin-project-container .header .addProjectBtn {
  padding: 0 0.7rem;
  padding-bottom: 2px;
  height: 33px;
  border-radius: 2px !important;
}
.admin-project-container .header .addProjectBtn span {
  font-size: 12px !important;
}
.admin-project-container .projects {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.admin-project-container .projects .box .action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 0;
  gap: 16px;
  margin-top: 0.4rem;
}
.admin-project-container .projects .box h4 {
  background-color: var(--iconBg);
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  color: hsl(229, 6%, 66%);
}
.admin-project-container .projects .box .icon {
  color: hsl(229, 6%, 66%);
  font-size: 18px;
}
.admin-project-container .projects .cyan h4:hover,
.admin-project-container .projects .cyan h4:hover .icon {
  color: var(--cyan);
}
.admin-project-container .projects .red h4:hover,
.admin-project-container .projects .red h4:hover .icon {
  color: var(--red);
}
.admin-project-container .projects .blue h4:hover,
.admin-project-container .projects .blue h4:hover .icon {
  color: var(--blue);
}
.admin-project-container .projects .orange h4:hover,
.admin-project-container .projects .orange h4:hover .icon {
  color: var(--orange);
}
.admin-project-container .projects .green h4:hover,
.admin-project-container .projects .green h4:hover .icon {
  color: var(--green);
}
.admin-project-container .projects .yellow h4:hover,
.admin-project-container .projects .yellow h4:hover .icon {
  color: var(--yellow);
}
.admin-project-container .projects .purple h4:hover,
.admin-project-container .projects .purple h4:hover .icon {
  color: var(--purple);
}
.admin-project-container .projects .primary h4:hover,
.admin-project-container .projects .primary h4:hover .icon {
  color: var(--primary);
}
.admin-project-container .projects .cyan,
.attendance-container .projects .cyan {
  border-top: 3px solid var(--cyan);
}
.admin-project-container .projects .red,
.attendance-container .projects .red {
  border-top: 3px solid var(--red);
}
.admin-project-container .projects .blue,
.attendance-container .projects .blue {
  border-top: 3px solid var(--blue);
}
.admin-project-container .projects .orange,
.attendance-container .projects .orange {
  border-top: 3px solid var(--orange);
}
.admin-project-container .projects .green,
.attendance-container .projects .green {
  border-top: 3px solid var(--green);
}
.admin-project-container .projects .yellow,
.attendance-container .projects .yellow {
  border-top: 3px solid var(--yellow);
}
.admin-project-container .projects .purple,
.attendance-container .projects .purple {
  border-top: 3px solid var(--purple);
}
.admin-project-container .projects .primary,
.attendance-container .projects .primary {
  border-top: 3px solid var(--primary);
}

.admin-project-container .projects .box h2 {
  color: var(--cardHeading);
  font-weight: 600;
  font-size: 18px;
}
.admin-project-container .projects .box p {
  color: hsl(229, 6%, 66%);
  font-size: 12px;
  width: 90%;
  margin-top: 4px;
  min-height: 40px;
}

@media (max-width: 650px) {
  .admin-project-container .box {
    margin: 10px 0;
  }
}

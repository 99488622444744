.project-card {
  background-color: white;
  box-shadow: var(--boxShadow);
  padding: 20px;
  border-radius: 10px;
  min-height: 400px;
}
.project-card p {
  font-size: 15px;
  height: 100px;
  text-align: justify;
}
.project-card button {
  margin-bottom: 10px;
}

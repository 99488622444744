@import url(https://fonts.googleapis.com/css2?family=Rubik&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cabin&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap);
* {
  /* scroll-behavior: smooth; */
}
body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background-color: #eaeaea;
}

.swal-text {
  text-align: center !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  letter-spacing: 1px;
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  /* border-radius: 20px; */
}
/* Track */
::-webkit-scrollbar-track {
  background: #555;

  /* border-radius: 20px; */
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
}

.general-header {
  background-color: #fff;
  box-shadow: var(--boxShadow);
  padding: 17px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* position: fixed; */
  width: 100%;
  z-index: 100;
  top: 0;
}
.general-header .Buttons {
  width: auto;
}
.general-header .Buttons button {
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.general-header .Buttons button:first-child {
  margin-right: 10px;
}
.general-header .vendor-info {
  /* display: flex;
  align-items: center; */
  width: 30%;
}
.general-header a {
  width: 50%;
}
.general-header .vendor-info p {
  margin-bottom: 0;
  margin-left: 10px;
  text-transform: capitalize;
  font-size: 17px;
  font-weight: bold;
}

.general-header h2 {
  color: var(--secondary-color);
  margin: 0;
  letter-spacing: 2px;
  font-weight: bold;
}

.empty {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.d-flex {
  display: flex;
}
.space-between {
  justify-content: space-between;
}
.m-20 {
  margin: 20px 0;
}

/* .infinityLoader {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--loaderBg);
  z-index: 10;
  backdrop-filter: blur(0.8px);
}
.infinityLoader img {
  z-index: 11;
} */
.editBtn {
  font-size: 20px;
  cursor: pointer;
}
.editBtn:hover {
  color: var(--primary);
}
.Heading {
  font-size: 20px;
  color: var(--text);
}

@media (max-width: 550px) {
  .general-header {
    flex-direction: column;
  }
  .general-header h2 {
    font-size: 20px;
    margin: 20px 0;
  }
  .general-header {
    align-items: flex-start;
  }
  .general-header .Buttons {
    align-items: flex-end;
    margin-left: auto;
  }
  .general-header .vendor-info p {
    font-size: 13px;
  }
  .infinityLoader img {
    height: 120px;
  }
}

.calendar {
  padding: 5rem;
  display: flex;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  flex-wrap: wrap;
  width: 100%;
  background-color: white;
}
.calendar .card {
  background-color: rgb(247, 247, 247);
  padding: 5rem;
  position: relative;
  border-radius: 10px;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}
.calendar .card h1 {
  position: absolute;
  top: 20px;
  left: 20px;
  color: gray;
}

.calendar .card p {
  position: absolute;
  top: 55px;
  left: 20px;
  color: gray;
}

.empty {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ant-btn-link {
  font-weight: bold !important;
  padding: 4px 0;
}
.ant-btn-primary {
  border-radius: 5px;
  font-weight: bold;
  letter-spacing: 2px;
  height: 40px;
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}
.ant-input,
.ant-picker {
  height: 37px;
  border-radius: 4px;
}
.ant-form-item-label label {
  letter-spacing: 1px;
}
.ant-checkbox-wrapper {
  padding: 5px 0;
}
.ant-divider-vertical {
  height: auto;
  /* border-left: 2px solid rgba(0, 0, 0, 0.06); */
  border-left: 1px solid rgba(138, 27, 106, 0.5);
}

.ant-pagination-total-text {
  display: flex;
  margin-right: auto;
}

.ant-modal-content {
  background-color: var(--cardColor) !important;
}
.ant-modal-close-x {
  color: var(--text) !important;
}
.ant-modal-header {
  background-color: var(--cardColor) !important;
}
.ant-modal-title {
  color: var(--text) !important;
}
.ant-form-item-label label {
  color: var(--text) !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 37px;
  border-radius: 4px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 20px;
  color: var(--primary-color);
}
.ant-input {
  background-color: transparent !important;
  border: 1.6px solid var(--borderLight);
  border-radius: 4px;
  color: var(--text) !important;
}
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  background-color: transparent !important;
}
.ant-select-dropdown {
  background-color: var(--cardColor) !important;
  color: var(--text) !important;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: var(--borderLight);
}
.ant-select-item {
  color: var(--text);
}
.ant-select-multiple .ant-select-selection-item {
  background-color: var(--background) !important;
  color: var(--text) !important;
  border-color: var(--borderLight) !important;
}
.ant-select-multiple .ant-select-selection-item-remove {
  color: var(--text) !important;
}
.ant-picker {
  background-color: var(--background) !important;
}
.ant-picker-suffix {
  color: gray !important;
}
.ant-picker-input > input {
  color: var(--text);
}

.ant-menu-dark .ant-menu-sub {
  background: var(--sidebar);
}

.ant-layout-sider::-webkit-scrollbar {
  display: none;
}
.themeSwitch {
}
.ant-switch-checked {
  background-color: var(--primary) !important;
}
.ant-switch-handle {
  top: 3px !important;
  width: 15px !important;
  height: 15px !important;
  left: 7px;
}
.ant-switch-checked .ant-switch-inner {
  margin-left: 3px !important;
}
.ant-drawer-content-wrapper {
  width: 100% !important;
}
.v-layout .company-logo {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 10vh;
  margin-bottom: 10px;
}
.v-layout .company-logo img {
  width: 70%;
}
.v-layout .company-title {
  font-size: 22px;
  color: var(--text) !important;
  text-transform: uppercase;
}

/* .v-layout .mainHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

.v-layout .headerLft {
  display: flex;
  align-items: center;
}
.v-layout .notiBtn {
  color: var(--text);
}

.v-layout .notiIcon {
  font-size: 28px;
  color: var(--text);
  width: -webkit-max-content;
  width: max-content;
}
.v-layout .notiIcon:hover {
  cursor: pointer;
  width: -webkit-max-content;
  width: max-content;
}

.v-layout .notiCount {
  position: absolute;
  right: 120px;
  top: 20px;
  z-index: 100;
  background-color: rgb(253, 68, 68);
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 7px;
  border-radius: 50%;
  font-weight: 1000;
  font-size: 10px;
  color: white !important;
}

.v-layout .notiComp {
  background-color: var(--background);
  /* background-color: white; */
  border: 2px solid gray;
  color: var(--text);
  position: absolute;
  right: 100px;
  top: 60px;
  max-width: 300px;
  max-height: 400px;
  min-height: 400px;
  min-width: 300px;
  padding: 10px;
  line-height: 20px !important;
  overflow: auto;
}
.v-layout .notiHeader {
  height: 30px;
  border-bottom: 1px solid gray;
  padding: 5px;
}
.v-layout .notiHeader span {
  color: var(--text);
}

.v-layout .notiFooter {
  margin-top: 10px;
  text-align: center;
}

.v-layout .notiCard {
  height: auto;
  max-height: 120px;
  min-height: 80px;
  border-bottom: 1px solid gray;
  padding: 5px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  /* width: 100%; */
}

.v-layout .message {
  color: var(--text);
  font-size: 12px;
  width: 70%;
}
.v-layout .date {
  color: var(--text);
  font-size: 10px;
  width: 30%;
  text-align: right;
  padding-left: 5px;
}

.v-layout .notiCard button {
  font-size: 9px;
  font-weight: 500;
  width: -webkit-max-content;
  width: max-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 10px;
  outline: none !important;
}

.v-layout .notiCard .ant-btn:focus {
  outline: none !important;
  border: none !important;
}

.sidebar-menu {
  background-color: transparent !important;
  color: var(--text) !important;
  font-size: 18px;
}
.sidebar-menu svg {
  font-size: 20px !important;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  border-right: 4px solid var(--primary) !important;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected
  span,
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected
  svg {
  color: var(--siderbarActive) !important;
}

.mainContent {
  background-color: var(--background);
  min-height: 88vh;
  padding: 0.6rem 1.4rem;
}
.ant-layout-header {
  background-color: var(--background) !important;
  min-height: 12vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 6;
  padding: 0 30px;
}

.v-layout {
  display: block;
}
.m-layout {
  display: none;
}

.ant-drawer-body {
  background-color: var(--sidebar) !important;
}

@media (max-width: 800px) {
  .v-layout {
    display: none;
  }
  .m-layout {
    display: block;
  }
}

.mobile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background);
  padding: 10px 4px;
  padding-right: 14px;
}
.m-drawer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.m-header {
  background-color: var(--background) !important;
  color: var(--text);
}
.m-children {
  padding: 14px;
  background-color: var(--background) !important;
  min-height: 89.5vh;
}
.backArrow {
  font-size: 20px;
  color: var(--text);
}
.m-drawer .logo {
  height: 20px;
}
.headerLft {
  display: flex;
  align-items: center;
}
.notiBtn {
  background-color: var(--background);
  border: none;
  color: var(--text);
  margin-right: 10px;
}
/* .notiBtn:hover {
  background-color: var(--background) !important;
  color: var(--text) !important;
  border: none;
  margin-right: 10px;
}
.notiBtn:focus{
  background-color: var(--background) !important;
  color: var(--text) !important;
  border: none;
  margin-right: 10px;
} */

.notiIcon {
  font-size: 28px;
  color: var(--text);
}

.notiCount {
  position: absolute;
  right: 110px;
  top: 15px;
  z-index: 1000;
  background-color: rgb(253, 68, 68);
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 7px;
  border-radius: 50%;
  font-weight: 1000;
  font-size: 10px;
  color: white !important;
}

.notiComp {
  background-color: var(--background);
  /* background-color: white; */
  border: 2px solid gray;
  color: var(--text);
  position: absolute;
  right: 50px;
  top: 60px;
  max-width: 300px;
  max-height: 400px;
  min-height: 400px;
  min-width: 300px;
  padding: 10px;
  line-height: 20px !important;
  overflow: auto;
  z-index: 1000;
}
.notiHeader {
  height: 30px;
  border-bottom: 1px solid gray;
  padding: 5px;
}
.notiHeader span {
  color: var(--text);
}

.notiFooter {
  margin-top: 10px;
  text-align: center;
}

.notiCard {
  height: auto;
  max-height: 100px;
  min-height: 80px;
  border-bottom: 1px solid gray;
  padding: 5px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  /* width: 100%; */
}

 .message {
  color: var(--text);
  font-size: 12px;
  width: 70%;
}
.date{
  color: var(--text);
  font-size: 10px;
  width: 30%;
  text-align: right;
  padding-left: 5px;
}

.notiCard button {
  font-size: 10px;
  font-weight: 500;
  width: -webkit-max-content;
  width: max-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 10px;
  outline: none !important;
}

.notiCard .ant-btn:focus {
  outline: none !important;
  border: none !important;
}


@media (max-width: 800px) {
  .ant-drawer-content-wrapper {
    width: 270px !important;
  }
}

@media (max-width: 650px) {
  .sidebar-menu {
    padding-left: 0 !important;
  }
  .logout-menu {
    padding-left: 24px !important;
  }
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

.project-card {
  background-color: white;
  box-shadow: var(--boxShadow);
  padding: 20px;
  border-radius: 10px;
  min-height: 400px;
}
.project-card p {
  font-size: 15px;
  height: 100px;
  text-align: justify;
}
.project-card button {
  margin-bottom: 10px;
}

.ant-table {
  background-color: var(--cardColor) !important;
  color: var(--text) !important;
}
.ant-table-thead > tr > th {
  background-color: var(--cardColor) !important;
  color: var(--text) !important;
}
.ant-spin-dot {
  font-size: 40px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 54%;
  left: 49%;
}
.ant-table .ant-table-expanded-row-fixed {
  min-height: 300px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-empty-img-simple-path,
.ant-empty-img-simple-ellipse {
  fill: var(--tableHeader) !important;
}
.ant-empty-description {
  color: var(--text) !important;
}
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background-color: var(--cardColor) !important;
}
.ant-pagination-prev .ant-pagination-item-link {
  background-color: transparent !important;
  color: var(--text) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-pagination-item {
  background-color: transparent !important;
}
.ant-pagination-item a {
  color: var(--text) !important;
}
.ant-pagination-next .ant-pagination-item-link {
  background-color: transparent !important;
  color: var(--text) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-pagination-item-active {
  background-color: transparent !important;
  color: var(--text) !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent !important;
  color: var(--text) !important;
}
.ant-select-arrow {
  color: var(--text) !important;
}
.ant-pagination-options {
  margin-right: 1rem;
}
/* .ant-tabs-top > .ant-tabs-nav::before , */
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  border-color: var(--borderLight) !important;
}

.action-div {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  grid-gap: 10px;
  gap: 10px;
}
.action-div .icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  padding: 4px;
  color: var(--primary);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
}
.action-div .icon:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.action-div .delete {
  color: var(--red);
}

.react-kanban-column-header.react-kanban-column-header {
  /* color: var(--secondary-color); */
  color: var(--text);
  font-size: 20px;
  font-weight: 500;
  background-color: transparent;
  letter-spacing: 1px;
  /* padding-bottom: 20px; */
  text-align: center;
  text-transform: uppercase;
}
.react-kanban-column.react-kanban-column {
  /* height: 500px !important;
    overflow-y: auto !important; */
  padding: 10px;
  margin: 0 10px;
  width: 300px;
  /* border-radius: 10px !important; */
  /* box-shadow: var(--boxShadow); */
  background-color: rgba(140, 135, 135, 0.15);
  /* background-color: white; */
}
.react-kanban-card.react-kanban-card {
  font-weight: 400;
  color: var(--text);
  width: 100%;
  border-top: 3px solid var(--orange);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  margin: 10px;
  background-color: var(--cardColor);
  font-size: 15px;
}
.react-kanban-card__title.react-kanban-card__title {
  /* border: none; */
  padding: 0;
}

.signin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: var(--background);
}
.signin .login-form {
  height: auto;
  margin: 20px 0;
  /* border: 1px #ddd solid; */
  box-shadow: var(--boxShadow);
  background-color: var(--cardColor);
  padding: 20px;
  border-radius: 2px;
  width: 45%;
}
.signin .login-form img {
  margin: 0 auto;
  display: flex;
}

.signin .login-form h2 {
  font-weight: bold;
  text-align: left;
  color: var(--text);
  margin-bottom: 10px;
}
.signin .login-form button {
  width: 100%;
  height: 45px;
  border: none;
  font-weight: bold;
  letter-spacing: 1px;
  background-color: var(--primary);
}
/* .signin .for-signup {
  text-align: center;
  width: 100%;
}
.signin .for-signup button {
  width: 100%;
  height: 40px;
  margin: 10px auto;
  border-color: #adb1b8 #a2a6ac #8d9096;
  border: 1px solid;
  background: linear-gradient(to bottom, #f7f8fa, #e7e9ec);
}

.signin .for-signup .signin-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47%;
}
.signin .for-signup .signin-btn svg {
  margin-right: 10px;
  font-size: 20px;
}
.signin .for-signup .signin-gf {
  display: flex;
} */

/* forget password  */
/* .signin-forget {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  text-align: center;
}
.signin-forget .forget-inner {
  width: 40%;
  height: 200px;
}
.signin-forget img,
.signin-forget h4,
.signup img,
.signup h4 {
  margin-bottom: 20px;
}
.for-signup h4 {
  margin: 10px 0;
}

.signin-forget p {
  margin-top: 20px;
  letter-spacing: 1px;
}
.signin-forget button {
  width: 100px;
  display: block;
  margin: 20px auto 0 auto;
} */

/* signup */

/* .signup {
  margin: 30px auto;
} */

/* media queries */

@media (max-width: 770px) {
  .signin .login-form {
    width: 60%;
  }
}
@media (max-width: 550px) {
  .signin .login-form {
    width: 90%;
  }
}

.home .project-stats h4 {
  /* text-decoration: underline; */
  color: black !important;
  font-size: 16px;
}
.home .project-stats {
  margin: 20px 0 50px 0;
}
.project-table .action-buttons button {
  margin: 0 10px;
}

.admin-project-container .box {
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  padding: 20px;
  margin: 10px;
  background-color: var(--cardColor);
}

.admin-project-container .header {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
}
.admin-project-container .header .addProjectBtn {
  padding: 0 0.7rem;
  padding-bottom: 2px;
  height: 33px;
  border-radius: 2px !important;
}
.admin-project-container .header .addProjectBtn span {
  font-size: 12px !important;
}
.admin-project-container .projects {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
}
.admin-project-container .projects .box .action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 0;
  grid-gap: 16px;
  gap: 16px;
  margin-top: 0.4rem;
}
.admin-project-container .projects .box h4 {
  background-color: var(--iconBg);
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  color: hsl(229, 6%, 66%);
}
.admin-project-container .projects .box .icon {
  color: hsl(229, 6%, 66%);
  font-size: 18px;
}
.admin-project-container .projects .cyan h4:hover,
.admin-project-container .projects .cyan h4:hover .icon {
  color: var(--cyan);
}
.admin-project-container .projects .red h4:hover,
.admin-project-container .projects .red h4:hover .icon {
  color: var(--red);
}
.admin-project-container .projects .blue h4:hover,
.admin-project-container .projects .blue h4:hover .icon {
  color: var(--blue);
}
.admin-project-container .projects .orange h4:hover,
.admin-project-container .projects .orange h4:hover .icon {
  color: var(--orange);
}
.admin-project-container .projects .green h4:hover,
.admin-project-container .projects .green h4:hover .icon {
  color: var(--green);
}
.admin-project-container .projects .yellow h4:hover,
.admin-project-container .projects .yellow h4:hover .icon {
  color: var(--yellow);
}
.admin-project-container .projects .purple h4:hover,
.admin-project-container .projects .purple h4:hover .icon {
  color: var(--purple);
}
.admin-project-container .projects .primary h4:hover,
.admin-project-container .projects .primary h4:hover .icon {
  color: var(--primary);
}
.admin-project-container .projects .cyan,
.attendance-container .projects .cyan {
  border-top: 3px solid var(--cyan);
}
.admin-project-container .projects .red,
.attendance-container .projects .red {
  border-top: 3px solid var(--red);
}
.admin-project-container .projects .blue,
.attendance-container .projects .blue {
  border-top: 3px solid var(--blue);
}
.admin-project-container .projects .orange,
.attendance-container .projects .orange {
  border-top: 3px solid var(--orange);
}
.admin-project-container .projects .green,
.attendance-container .projects .green {
  border-top: 3px solid var(--green);
}
.admin-project-container .projects .yellow,
.attendance-container .projects .yellow {
  border-top: 3px solid var(--yellow);
}
.admin-project-container .projects .purple,
.attendance-container .projects .purple {
  border-top: 3px solid var(--purple);
}
.admin-project-container .projects .primary,
.attendance-container .projects .primary {
  border-top: 3px solid var(--primary);
}

.admin-project-container .projects .box h2 {
  color: var(--cardHeading);
  font-weight: 600;
  font-size: 18px;
}
.admin-project-container .projects .box p {
  color: hsl(229, 6%, 66%);
  font-size: 12px;
  width: 90%;
  margin-top: 4px;
  min-height: 40px;
}

@media (max-width: 650px) {
  .admin-project-container .box {
    margin: 10px 0;
  }
}

.admin-employee-container {
  padding-left: 0.5rem;
}
.admin-employee-container .header {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
  /* justify-content: flex-end; */
}
.admin-employee-container .header .addEmployeeBtn {
  padding: 0 0.7rem;
  padding-bottom: 2px;
  height: 33px;
  border-radius: 2px !important;
}
.admin-employee-container .header .addEmployeeBtn span {
  font-size: 12px !important;
}

.projectDetails-container .header {
  padding-bottom: 2rem;
}
.projectDetails-container .header .back {
  cursor: pointer;
  color: var(--text);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  padding: 4px;
  background-color: rgba(128, 128, 128, 0.363);
  border-radius: 50%;
  margin-bottom: 1rem;
}
.projectDetails-container .header .back:hover {
  color: var(--primary);
  background-color: white;
}
.projectDetails-container h2,
.projectDetails-container .header p {
  font-size: 18px;
  color: var(--text);
  margin-top: 0.6rem;
}
.projectDetails-container .header p span {
  font-size: 14px;
}

.projectDetails-container .filterDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-top: 1rem;
  flex-wrap: wrap;
}
.projectDetails-container .filterDiv .rightFilter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.projectDetails-container .project-todos {
  margin: 5rem 0;
}
.projectDetails-container .project-todos h2 {
  font-size: 30px;
  margin-bottom: 40px;
}
.projectDetails-container .project-todos .ant-mentions {
  border: 1px solid var(--borderLight) !important;
  margin-top: 2px;
  /* padding: 2px; */
}
.projectDetails-container .project-todos .ant-mentions textarea {
  background: var(--background);
  /* border: var(--borderLight) !important; */
  /* border: none !important; */
  color: var(--text);
}

.summary-container .summaryHeading {
  font-size: 24px;
  font-size: 600;
  color: var(--text);
  text-transform: capitalize;
  text-decoration: underline;
  padding: 1rem 0;
}
.summary-container .header2 {
  margin-top: 3rem;
}

.summary-container .card {
  background-color: var(--cardColor);
  padding: 2rem;
}
.summary-container .card2 {
  padding: 2rem;
}

.summary-container .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.summary-container .label {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  color: rgb(136, 136, 136);
}
.summary-container .label span {
  color: var(--text);
}

@media (max-width: 770px) {
  .summary-container .header {
    margin-bottom: 1rem;
  }
  .summary-container .card {
    padding: 1rem;
  }
  .summary-container .card2 {
    overflow: auto;
    padding: 0;
  }
  .coloumnChart {
    width: 600px;
    padding: 1rem;
  }
  .summary-container .card2::-webkit-scrollbar {
    display: none !important;
  }
}

.todo-main h1,
.todo-main h4,
.todo-main h2,
.todo-main h3 {
  color: var(--text);
  margin-bottom: 10px;
}
.todo-main .todo-input {
  /* width: 50%; */
  margin: 30px 0;
}

.attendance-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 220px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  padding: 20px;
  margin: 10px;
  background-color: var(--cardColor);
}
.attendance-card .circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--primary);
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 800;
  color: var(--text);
}
.attendance-card .ant-divider.ant-divider {
  border-top: 1px solid var(--cardHeading);
  margin: 10px 0;
}
.todo-main .ant-image.ant-image {
  display: flex;
  justify-content: center;
}
.attendance-card .Dates {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.attendance-card .last7day {
  overflow: auto;
  margin-top: 10px;
  display: flex;
}
.attendance-card .date-section h4 {
  margin: 0;
  font-size: 12px;
}

.attendance-card .date-section {
  width: 100px;
  border: 1px solid var(--text);
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: center;
}

.attendance-container {
}

.reports-main h1,
.reports-main h2,
.reports-main p {
  font-size: 18px;
  color: var(--text);
  margin-top: 0.6rem;
  text-decoration: underline;
  margin-bottom: 40px;
}
.reports-main .ant-divider-horizontal {
  border-color: var(--text);
}
.reports-main .mt-7 {
  margin-top: 5rem;
}

.screenshot-section {
  margin: 40px 0;
}

.expense-main h1,
.expense-main h2,
.expense-main h3,
.expense-main p {
  color: var(--text);
  margin-top: 0.6rem;
}
.expense-main h3 {
  margin-bottom: 40px;
}
.expense-main .ant-divider-horizontal {
  border-color: var(--text);
}
.expense-card {
  display: flex;
  height: -webkit-max-content;
  height: max-content;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  padding: 20px;
  background-color: var(--cardColor);
}

.maintanence-container{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #212528;
    width: 100%;
    min-height: 100vh;
}

.maintanence-container .main{
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.maintanence-container .main h2{
    color: #EBE7D9;
    font-size: 22px;
    position: absolute;
    bottom: 150px;
    
}
/* Third Party CSS */

/* Theme CSS */

/* Layout CSS */

/* Component CSS */

/* Page CSS */

.summary {
  margin-bottom: 2rem;
  padding: 20px;
  color: var(--text);
}
.summary .card {
  border: 1px solid var(--borderLight);
  padding: 10px;
  box-shadow: var(--cardShadow);
  /* min-height: 200px; */
}
.card-top {
  font-size: 1.2rem;
  font-weight: 500;
  /* margin-bottom: 40px; */
}
.summary .card div > div {
  /* width: 90%; */
  /* margin: auto; */
}
.card-bottom {
  /* background: #000; */
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  /* padding: 10px 0; */
  /* margin-top: 40px; */
  /* height: 100%; */
}

.admin-dashboard-container
  .ant-divider-horizontal.ant-divider-with-text-center::before,
.admin-dashboard-container
  .ant-divider-horizontal.ant-divider-with-text-center::after {
  border-top: 1px solid var(--text);
}
.admin-dashboard-container
  .ant-divider-horizontal.ant-divider-with-text-center
  h4 {
  color: var(--text);
  font-size: 1.2rem;
  font-weight: 500;
}
.admin-dashboard-container
  .ant-divider-horizontal.ant-divider-with-text-center
  h3 {
  color: var(--text);
  font-size: 1.2rem;
  font-weight: 500;
}
.today-attendance .table-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-header h4{
  color: var(--text);
  font-size: 1rem;
  font-weight: 500;
}
.table-body{
  /* min-height: 500px; */
}

.infinityLoader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .infinityLoader .bar {
    margin-left: 2px;
    width: 10px;
    height: 70px;
    background: #F55139;
    display: inline-block;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    /*   box-shadow:5px 10px 20px inset rgba(255,23,25.2); */
    -webkit-animation: loader 1.2s linear infinite;
            animation: loader 1.2s linear infinite;
  }
  .infinityLoader .bar1 {
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
  }
  .infinityLoader .bar2 {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }
  .infinityLoader .bar3 {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
  }
  .infinityLoader .bar4 {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }
  .infinityLoader .bar5 {
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  .infinityLoader .bar6 {
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }
  .infinityLoader .bar7 {
    -webkit-animation-delay: 0.7s;
            animation-delay: 0.7s;
  }
  .infinityLoader .bar8 {
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }
  
  @-webkit-keyframes loader {
    0% {
      -webkit-transform: scaleY(0.1);
              transform: scaleY(0.1);
      background: transparent;
    }
    50% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      background: #F55139;
    }
    100% {
      -webkit-transform: scaleY(0.1);
              transform: scaleY(0.1);
      background: transparent;
    }
  }
  
  @keyframes loader {
    0% {
      -webkit-transform: scaleY(0.1);
              transform: scaleY(0.1);
      background: transparent;
    }
    50% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      background: #F55139;
    }
    100% {
      -webkit-transform: scaleY(0.1);
              transform: scaleY(0.1);
      background: transparent;
    }
  }
  
  @media (max-width: 780px) {
    .infinityLoader {
      height: 89vh;
    }
  }
.roles-container h3 {
  color: var(--text);
}
.roles-container {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

/* .roles-container .select {
  margin-bottom: 20px;
} */

.tree {
  margin-top: 5px;
  background-color: var(--primary-color);
  color: var(--text);
}

.tree .ant-tree-checkbox-inner {
  background-color: var(--primary-color);
  border-color: var(--text);
}

.tree .ant-tree-checkbox-inner::after {
  background-color: var(--text);
}

.roles-container .ant-btn {
  /* margin: 0 auto; */
  align-self: center;
}

/* .roles-container .roles-select{
  display: flex;
  align-items: center;
  margin-bottom: 30px;
} */

/* .roles-container .roles-input {
  position: fixed;
} */

.edit-btn {
  padding: 0 0.7rem;
  padding-bottom: 2px;
  height: 33px;
  border-radius: 2px !important;
  border: none;
  background-color: transparent;
  color: var(--text);
}
.edit-btn :hover {
  background-color: transparent !important;
  /* color: var(--text); */
}

.noti-cont {
  background: var(--background);
}

.noti-cont h2 {
  color: var(--text);
}
.noti-cont .ant-divider {
  background-color: var(--text);
}

.noti-cont .noti-caed {
  padding: 20px 10px;
  border: 1px solid var(--borderLight);
  margin-bottom: 10px;
  border-radius: 20px;
  box-shadow: var(--cardShadow);
  min-height: 110px;
}

.noti-cont .notisCard span {
  color: var(--text);
  margin-top: 10px;
}

.logs-container {
}

.logs-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  /* width: 40%; */
}
.logs-head h2 {
  color: var(--text);
  margin-bottom: 0;
}
.logs-container .ant-divider {
  background-color: var(--text);
}

.log-col {
  padding: 20px 10px;
  
  border: 1px solid var(--borderLight);
  margin-bottom: 10px;
  border-radius: 20px;
  box-shadow: var(--cardShadow);
  min-height: 110px;
}
.log-col .logs-card .logs-top {
  margin-bottom: 5px;
}

.log-col .logs-card .logs-top span {
  color: var(--text);
  font-size: 14px;
}
.log-col .logs-card .logs-message span {
  color: var(--text);
  font-size: 14px;
}

.noti-add-cont h2{
    color: var(--text);
}
.noti-add-cont .ant-divider{
    background-color: var(--text);
    margin-bottom: 80px;
}
.noti-add-cont .ant-input{
    background: var(--background);
    border: 1px solid var(--borderLight);
    color: var(--text);
    width: 80%;
    padding: 5px;
    margin-bottom: 50px;
}
.deleted-todo-header h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text);
  /* margin-top: 2rem; */
  margin-bottom: 2rem;
}

.checkin-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* padding: 20px; */
  margin: 20px 0;
}

.checkin-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.checkin-head h2 {
  color: var(--text);
  font-weight: bold;
}

.checkin-head .ant-picker {
  width: 300px !important;
}


.infinityLoader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .infinityLoader .bar {
    margin-left: 2px;
    width: 10px;
    height: 70px;
    background: #F55139;
    display: inline-block;
    transform-origin: bottom center;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    /*   box-shadow:5px 10px 20px inset rgba(255,23,25.2); */
    animation: loader 1.2s linear infinite;
  }
  .infinityLoader .bar1 {
    animation-delay: 0.1s;
  }
  .infinityLoader .bar2 {
    animation-delay: 0.2s;
  }
  .infinityLoader .bar3 {
    animation-delay: 0.3s;
  }
  .infinityLoader .bar4 {
    animation-delay: 0.4s;
  }
  .infinityLoader .bar5 {
    animation-delay: 0.5s;
  }
  .infinityLoader .bar6 {
    animation-delay: 0.6s;
  }
  .infinityLoader .bar7 {
    animation-delay: 0.7s;
  }
  .infinityLoader .bar8 {
    animation-delay: 0.8s;
  }
  
  @keyframes loader {
    0% {
      transform: scaleY(0.1);
      background: transparent;
    }
    50% {
      transform: scaleY(1);
      background: #F55139;
    }
    100% {
      transform: scaleY(0.1);
      background: transparent;
    }
  }
  
  @media (max-width: 780px) {
    .infinityLoader {
      height: 89vh;
    }
  }
.maintanence-container{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #212528;
    width: 100%;
    min-height: 100vh;
}

.maintanence-container .main{
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.maintanence-container .main h2{
    color: #EBE7D9;
    font-size: 22px;
    position: absolute;
    bottom: 150px;
    
}
.todo-main h1,
.todo-main h4,
.todo-main h2,
.todo-main h3 {
  color: var(--text);
  margin-bottom: 10px;
}
.todo-main .todo-input {
  /* width: 50%; */
  margin: 30px 0;
}

.attendance-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 220px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  padding: 20px;
  margin: 10px;
  background-color: var(--cardColor);
}
.attendance-card .circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--primary);
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 800;
  color: var(--text);
}
.attendance-card .ant-divider.ant-divider {
  border-top: 1px solid var(--cardHeading);
  margin: 10px 0;
}
.todo-main .ant-image.ant-image {
  display: flex;
  justify-content: center;
}
.attendance-card .Dates {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.attendance-card .last7day {
  overflow: auto;
  margin-top: 10px;
  display: flex;
}
.attendance-card .date-section h4 {
  margin: 0;
  font-size: 12px;
}

.attendance-card .date-section {
  width: 100px;
  border: 1px solid var(--text);
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: center;
}

.attendance-container {
}

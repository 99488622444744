.summary {
  margin-bottom: 2rem;
  padding: 20px;
  color: var(--text);
}
.summary .card {
  border: 1px solid var(--borderLight);
  padding: 10px;
  box-shadow: var(--cardShadow);
  /* min-height: 200px; */
}
.card-top {
  font-size: 1.2rem;
  font-weight: 500;
  /* margin-bottom: 40px; */
}
.summary .card div > div {
  /* width: 90%; */
  /* margin: auto; */
}
.card-bottom {
  /* background: #000; */
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  /* padding: 10px 0; */
  /* margin-top: 40px; */
  /* height: 100%; */
}

.admin-dashboard-container
  .ant-divider-horizontal.ant-divider-with-text-center::before,
.admin-dashboard-container
  .ant-divider-horizontal.ant-divider-with-text-center::after {
  border-top: 1px solid var(--text);
}
.admin-dashboard-container
  .ant-divider-horizontal.ant-divider-with-text-center
  h4 {
  color: var(--text);
  font-size: 1.2rem;
  font-weight: 500;
}
.admin-dashboard-container
  .ant-divider-horizontal.ant-divider-with-text-center
  h3 {
  color: var(--text);
  font-size: 1.2rem;
  font-weight: 500;
}
.today-attendance .table-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-header h4{
  color: var(--text);
  font-size: 1rem;
  font-weight: 500;
}
.table-body{
  /* min-height: 500px; */
}

.summary-container .summaryHeading {
  font-size: 24px;
  font-size: 600;
  color: var(--text);
  text-transform: capitalize;
  text-decoration: underline;
  padding: 1rem 0;
}
.summary-container .header2 {
  margin-top: 3rem;
}

.summary-container .card {
  background-color: var(--cardColor);
  padding: 2rem;
}
.summary-container .card2 {
  padding: 2rem;
}

.summary-container .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.summary-container .label {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  color: rgb(136, 136, 136);
}
.summary-container .label span {
  color: var(--text);
}

@media (max-width: 770px) {
  .summary-container .header {
    margin-bottom: 1rem;
  }
  .summary-container .card {
    padding: 1rem;
  }
  .summary-container .card2 {
    overflow: auto;
    padding: 0;
  }
  .coloumnChart {
    width: 600px;
    padding: 1rem;
  }
  .summary-container .card2::-webkit-scrollbar {
    display: none !important;
  }
}

.checkin-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* padding: 20px; */
  margin: 20px 0;
}

.checkin-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.checkin-head h2 {
  color: var(--text);
  font-weight: bold;
}

.checkin-head .ant-picker {
  width: 300px !important;
}

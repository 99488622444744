.projectDetails-container .header {
  padding-bottom: 2rem;
}
.projectDetails-container .header .back {
  cursor: pointer;
  color: var(--text);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  padding: 4px;
  background-color: rgba(128, 128, 128, 0.363);
  border-radius: 50%;
  margin-bottom: 1rem;
}
.projectDetails-container .header .back:hover {
  color: var(--primary);
  background-color: white;
}
.projectDetails-container h2,
.projectDetails-container .header p {
  font-size: 18px;
  color: var(--text);
  margin-top: 0.6rem;
}
.projectDetails-container .header p span {
  font-size: 14px;
}

.projectDetails-container .filterDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-top: 1rem;
  flex-wrap: wrap;
}
.projectDetails-container .filterDiv .rightFilter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.projectDetails-container .project-todos {
  margin: 5rem 0;
}
.projectDetails-container .project-todos h2 {
  font-size: 30px;
  margin-bottom: 40px;
}
.projectDetails-container .project-todos .ant-mentions {
  border: 1px solid var(--borderLight) !important;
  margin-top: 2px;
  /* padding: 2px; */
}
.projectDetails-container .project-todos .ant-mentions textarea {
  background: var(--background);
  /* border: var(--borderLight) !important; */
  /* border: none !important; */
  color: var(--text);
}

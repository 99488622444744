.react-kanban-column-header.react-kanban-column-header {
  /* color: var(--secondary-color); */
  color: var(--text);
  font-size: 20px;
  font-weight: 500;
  background-color: transparent;
  letter-spacing: 1px;
  /* padding-bottom: 20px; */
  text-align: center;
  text-transform: uppercase;
}
.react-kanban-column.react-kanban-column {
  /* height: 500px !important;
    overflow-y: auto !important; */
  padding: 10px;
  margin: 0 10px;
  width: 300px;
  /* border-radius: 10px !important; */
  /* box-shadow: var(--boxShadow); */
  background-color: rgba(140, 135, 135, 0.15);
  /* background-color: white; */
}
.react-kanban-card.react-kanban-card {
  font-weight: 400;
  color: var(--text);
  width: 100%;
  border-top: 3px solid var(--orange);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  margin: 10px;
  background-color: var(--cardColor);
  font-size: 15px;
}
.react-kanban-card__title.react-kanban-card__title {
  /* border: none; */
  padding: 0;
}

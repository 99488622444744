.logs-container {
}

.logs-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  /* width: 40%; */
}
.logs-head h2 {
  color: var(--text);
  margin-bottom: 0;
}
.logs-container .ant-divider {
  background-color: var(--text);
}

.log-col {
  padding: 20px 10px;
  
  border: 1px solid var(--borderLight);
  margin-bottom: 10px;
  border-radius: 20px;
  box-shadow: var(--cardShadow);
  min-height: 110px;
}
.log-col .logs-card .logs-top {
  margin-bottom: 5px;
}

.log-col .logs-card .logs-top span {
  color: var(--text);
  font-size: 14px;
}
.log-col .logs-card .logs-message span {
  color: var(--text);
  font-size: 14px;
}

.admin-employee-container {
  padding-left: 0.5rem;
}
.admin-employee-container .header {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
  /* justify-content: flex-end; */
}
.admin-employee-container .header .addEmployeeBtn {
  padding: 0 0.7rem;
  padding-bottom: 2px;
  height: 33px;
  border-radius: 2px !important;
}
.admin-employee-container .header .addEmployeeBtn span {
  font-size: 12px !important;
}

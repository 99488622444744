.roles-container h3 {
  color: var(--text);
}
.roles-container {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

/* .roles-container .select {
  margin-bottom: 20px;
} */

.tree {
  margin-top: 5px;
  background-color: var(--primary-color);
  color: var(--text);
}

.tree .ant-tree-checkbox-inner {
  background-color: var(--primary-color);
  border-color: var(--text);
}

.tree .ant-tree-checkbox-inner::after {
  background-color: var(--text);
}

.roles-container .ant-btn {
  /* margin: 0 auto; */
  align-self: center;
}

/* .roles-container .roles-select{
  display: flex;
  align-items: center;
  margin-bottom: 30px;
} */

/* .roles-container .roles-input {
  position: fixed;
} */

.edit-btn {
  padding: 0 0.7rem;
  padding-bottom: 2px;
  height: 33px;
  border-radius: 2px !important;
  border: none;
  background-color: transparent;
  color: var(--text);
}
.edit-btn :hover {
  background-color: transparent !important;
  /* color: var(--text); */
}
